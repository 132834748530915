import router from "../router";
// import { useRouter } from "vue-router";
import { getUserData, getStudentData } from "./storeData.js";
import { Modal } from "ant-design-vue";
import { computed } from "vue";
import { checkIsOrder } from "@/api/main.js";

const navigateTo = (_path, _query) => {
  router.push({ path: _path, query: _query });
};
const replaceTo = (_path, _query) => {
  router.replace({ path: _path, query: _query });
};

const isDuringDate = (beiginDateStr, endDateStr) => {
  let curDate = new Date();
  let beiginDate = new Date(beiginDateStr);
  let endDate = new Date(endDateStr);
  if (curDate >= beiginDate && curDate <= endDate) {
    return true;
  }
  return false;
};

const errorTips = (message) => {
  Modal.error({
    title: message,
    okText: "确定",
    onOk() {},
  });
};

const isVipResource = async (
  _path,
  _query,
  _studentId,
  _per,
  _limit,
  _section
) => {
  console.log(
    "isVipResource",
    `_per:${_per}, _limit:${_limit}, _section:${_section}, _studentId:${_studentId}`
  );
  let user = computed(() => getUserData()).value;
  let student = await getStudentData();
  let _student = student.filter((res) => _studentId == res.student_id);
  let limit = 0;
  let section = 0;
  if (!user.user_id) {
    Modal.confirm({
      title: "请先登录本网站",
      okText: "去登录",
      cancelText: "取消",
      onOk() {
        navigateTo("/login");
      },
      onCancel() {},
    });
    return false;
  } else {
    if (_student.length > 0) {
      limit = _student[0]?.class_day_limit || 0;
      section = _student[0]?.class_day_section || 0;
    }
    let class_end_time = _student[0]?.class_end_time.replace(/-/g, "/");
    let class_start_time = _student[0]?.class_start_time.replace(/-/g, "/");
    if (user.user_isvip == 1) {
      router.push({ path: _path, query: _query });
    } else if (_studentId) {
      if (isDuringDate(class_start_time, class_end_time)) {
        if (_per < 100) {
          if (limit && _limit >= limit) {
            errorTips("今日学习时长已达上限，请明日再来！");
            return false;
          }
          if (section && _section >= section) {
            errorTips("今日学习时长已达上限，请明日再来！");
            return false;
          }
          router.push({ path: _path, query: _query });
        } else if (_per >= 100) {
          errorTips("当前课程已学完！");
          return false;
        } else {
          errorTips("您无权观看此课程");
          return false;
        }
      } else {
        errorTips("当前不在学习时段！");
        return false;
      }
    } else {
      errorTips("您没有权限学习，请联系培训机构");
      return false;
    }
  }
};

// 校验权限
const authResource = (resource_id, student_id) => {
  return new Promise((resolve, reject) => {
    checkIsOrder({ resource_id, student_id })
      .then((res) => {
        if (res.code == 200) {
          resolve(true);
        } else {
          reject(res);
        }
      })
      .catch((res) => {
        console.log(res);
        reject(res);
      });
  });
};
const commentNavigater = async (
  _path,
  _query,
  _studentId,
  _per,
  _limit,
  _section,
  _resourceId
) => {
  console.log(
    "isVipResource",
    `_per:${_per}, _limit:${_limit}, _section:${_section}, _studentId:${_studentId}`
  );
  let user = computed(() => getUserData()).value;
  let student = await getStudentData();
  let _student = student.filter((res) => _studentId == res.student_id);
  let limit = 0;
  let section = 0;
  if (!user.user_id) {
    Modal.confirm({
      title: "请先登录本网站",
      okText: "去登录",
      cancelText: "取消",
      onOk() {
        navigateTo("/login");
      },
      onCancel() {},
    });
    return false;
  } else {
    if (_student.length > 0) {
      limit = _student[0]?.class_day_limit || 0;
      section = _student[0]?.class_day_section || 0;
    }
    let class_end_time = _student[0]?.class_end_time.replace(/-/g, "/");
    let class_start_time = _student[0]?.class_start_time.replace(/-/g, "/");
    if (user.user_isvip == 1) {
      router.replace({ path: _path, query: _query });
    } else if (_studentId) {
      authResource(_resourceId, _studentId)
        .then(() => {
          if (isDuringDate(class_start_time, class_end_time)) {
            if (_per < 100) {
              if (limit && _limit >= limit) {
                errorTips("今日学习时长已达上限，请明日再来！");
                return false;
              }
              if (section && _section >= section) {
                errorTips("今日学习时长已达上限，请明日再来！");
                return false;
              }
              router.replace({ path: _path, query: _query });
            } else if (_per >= 100) {
              errorTips("当前课程已学完！");
              return false;
            } else {
              errorTips("您无权观看此课程");
              return false;
            }
          } else {
            errorTips("当前不在学习时段！");
            return false;
          }
        })
        .catch((res) => {
          console.log(res);
          errorTips(res?.msg || "您没有权限学习，请联系培训机构");
        });
    } else {
      errorTips("您没有权限学习，请联系培训机构");
      return false;
    }
  }
};

const url = {
  navigateTo: navigateTo,
  replaceTo: replaceTo,
  isVipResource,
  commentNavigater,
};

export default url;
