<template>
  <a-layout>
    <a-layout-header
      :style="{
        position: 'fixed',
        zIndex: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        background: '#fff',
        boxShadow: '0px 5px 5px #eaeaea60',
      }"
    >
      <div id="pageMain">
        <div class="logo">
          <img src="~@/assets/logo.png" alt="" />
        </div>
        <a-menu
          :selectedKeys="selectedKeys"
          theme="light"
          mode="horizontal"
          :style="{ lineHeight: '64px', fontSize: '16px' }"
        >
          <a-menu-item key="/index" @click="changeRoute('/index')"
            >网站首页</a-menu-item
          >
          <a-menu-item key="/course" @click="changeRoute('/course')"
            >课程分类</a-menu-item
          >
          <a-menu-item
            key="/question/index"
            @click="changeRoute('/question/index')"
            >题库广场</a-menu-item
          >
          <a-menu-item key="/exam/index" @click="changeRoute('/exam/index')"
            >线上考试</a-menu-item
          >
          <a-menu-item key="/about" @click="changeRoute('/about')"
            >关于我们</a-menu-item
          >
        </a-menu>
      </div>
      <div class="login-text">
        <notice-icon v-if="userData.user_id"></notice-icon>
        <a-dropdown v-if="userData.user_id" :placement="'bottomLeft'">
          <a-space>
            <img
              v-if="userData.user_avatar"
              :src="userData.user_avatar"
              alt=""
              class="user-avatar"
            />
            <div v-else class="user-avatar-none">
              <user-outlined />
            </div>
            <span style="cursor: pointer">{{ userData.user_name }}</span>
          </a-space>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="goToCourse">
                <a-space><read-outlined />我的课程</a-space>
              </a-menu-item>
              <a-menu-item @click="goToMine">
                <a-space><user-outlined />个人中心</a-space>
              </a-menu-item>
              <a-menu-item @click="logoutFun">
                <a-space><logout-outlined />退出登录</a-space>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <span v-else class="login-text-letter" @click="goToLogin">登录</span>
      </div>
    </a-layout-header>
    <a-layout-content
      :style="{
        marginTop: '64px',
        overflowY: 'auto',
        flex: '1 0 auto',
        background: '#f2f2f2',
      }"
    >
      <!-- <div style="width: 100%; height: 100%"> -->
        <router-view />
      <!-- </div> -->
    </a-layout-content>
    <a-layout-footer>
      <div class="page-main flex-between yxzx-footer">
        <div>
          <div class="footer-row">
            <a-space>
              <div @click="goToPage('/index')">网站首页</div>
              <div @click="goToPage('/course')">课程分类</div>
              <div @click="goToPage('/question/index')">题库广场</div>
              <div @click="goToPage('/exam/index')">线上考试</div>
              <div @click="goToPage('/about')">关于我们</div>
            </a-space>
          </div>
          <a-space
            >Copyright ©2022
            <a style="color: #fff" href="https://yxzx.bj-jiling.com"
              >yxzx.bj-jiling.com</a
            >
            北京技领科技有限公司 版权所有
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              style="color: #fff"
            >
              <i class="beian"></i>
              冀ICP备 2021011022号-1</a
            >
          </a-space>
        </div>
        <div>
          <a-popover>
            <template #content>
              <img src="~@/assets/wechat.jpg" class="wechat" />
              <div>扫码进入微信小程序</div>
            </template>
            <wechat-outlined style="font-size: 28px" />
          </a-popover>
        </div>
      </div>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import url from "../../utils/url.js";
import {
  WechatOutlined,
  LogoutOutlined,
  UserOutlined,
  ReadOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { notification } from "ant-design-vue";
import NoticeIcon from "@/components/noticeicon/index.vue";

export default defineComponent({
  name: "Layout",
  components: {
    WechatOutlined,
    LogoutOutlined,
    UserOutlined,
    ReadOutlined,
    NoticeIcon,
  },
  setup() {
    const store = useStore();
    const selectedKeys = ref([]);
    const router = useRouter();
    const route = useRoute();
    const changeRoute = (e) => {
      router.push(e);
    };

    const goToMine = () => {
      url.navigateTo("/mine", {});
    };
    const goToCourse = () => {
      url.navigateTo("/mine", { type: "course" });
    };
    const goToLogin = () => {
      url.navigateTo("/login", {});
    };

    const goToPage = (_page) => {
      url.navigateTo(_page);
    };

    // selectedKeys.value = computed(() => {
    //   let _path = router.currentRoute.value.path;
    //   console.log("_path", _path);
    //   return [_path];
    // }).value;
    watch(
      () => route,
      (val) => {
        selectedKeys.value = [val.matched[1].path];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const userData = computed(() => {
      return store.getters["userData"];
    });

    const logoutFun = () => {
      store
        .dispatch("LOGOUT")
        .then(() => {
          notification.success({
            message: "退出成功",
            duration: 1.5,
            description: "正在跳转页面中，请稍后……",
            onClose: () => {
              url.navigateTo("/index");
            },
          });
        })
        .catch((res) => {
          console.log(res);
        });
    };

    return {
      selectedKeys,
      changeRoute,
      goToMine,
      goToPage,
      goToCourse,
      userData,
      logoutFun,
      goToLogin,
    };
  },
});
</script>
<style lang="less">
.ant-layout {
  height: 100%;
  background: transparent;
}
.logo {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  margin: 12px 24px 12px 0;
  float: left;
  img {
    display: block;
    width: 40px;
    height: 40px;
  }
}
.login-text {
  color: #7e8c8d;
  letter-spacing: 2px;
  display: flex;
  // cursor: pointer;
  .ant-space-item span {
    margin: 0 12px;
  }
  .login-text-letter {
    cursor: pointer;
  }
  .user-avatar {
    display: block;
    height: 38px;
    width: 38px;
    border-radius: 50%;
  }
  .user-avatar-none {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    font-size: 18px;
    background: #ddd;
    border-radius: 50%;
  }
}
.ant-layout-content {
  min-width: 1200px;
}
.ant-layout-footer {
  min-width: 1200px;
  padding: 28px 0;
  background-color: #1c1f21;
  color: #fff;
  flex: "0 0 auto";
  .yxzx-footer {
    .footer-row {
      margin-bottom: 12px;
      font-size: 15px;
      div {
        cursor: pointer;
      }
    }
    .beian {
      background: url(//www.imooc.com/static/img/beianicon.png) no-repeat 0 0;
      width: 16px;
      height: 16px;
      vertical-align: middle;
      background-size: cover;
      display: inline-block;
      margin-right: 4px;
      margin-top: -4px;
    }
  }
}
.wechat {
  display: block;
  width: 120px;
  margin-bottom: 12px;
}
</style>
