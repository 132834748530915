export default {
  // api请求前缀
  // #ifdef H5
  webUrl: "/api",
  // #endif
  // #ifndef H5
  // webUrl:'https://yxzx.qinghuiche.com',
  webUrl: "https://yxzx.bj-jiling.com/",
  // #endif
  uploadImageUrl: "https://yxzx-public.oss-cn-beijing.aliyuncs.com/",
  timeout: "360000",
};
