import request from "@/utils/request";

export async function onLogin(params) {
  return request.post("index/login/onLoginByUserPhone", params);
}
export async function getUserData(params) {
  return request.post("/index/user/getUserData", params);
}
export async function getExamList(params) {
  return request.post("/index/exam/getExamList", params);
}
export async function insertExamRecordData(params) {
  return request.post("/index/exam/insertExamRecordData", params);
}
export async function getExamQuestionList(params) {
  return request.post("/index/exam/getExamQuestionList", params);
}
export async function updateExamRecordData(params) {
  return request.post("/index/exam/updateExamRecordData", params);
}
export async function getExamRecordList(params) {
  return request.post("/index/exam/getExamRecordList", params);
}
export async function getExamRecordData(params) {
  return request.post("/index/exam/getExamRecordData", params);
}
export async function getSliderList(params) {
  return request.post("/index/basic/getSliderList", params);
}
export async function getJobList(params) {
  return request.post("/index/job/getJobList", params);
}
export async function getCourseList(params) {
  return request.post("/index/course/getCourseList", params);
}
export async function getUserCourseList(params) {
  return request.post("/index/course/getUserCourseList", params);
}
export async function getCourseData(params) {
  return request.post("/index/course/getCourseData", params);
}
export async function getSettingData(params) {
  return request.post("/index/basic/getSettingData", params);
}
export async function getPracticeList(params) {
  return request.post("/index/practice/getPracticeList", params);
}
export async function getPracticeQuestionList(params) {
  return request.post("/index/practice/getPracticeQuestionList", params);
}
export async function getStudentList(params) {
  return request.post("/index/user/getStudentList", params);
}
export async function getNoticeList(params) {
  return request.post("/index/basic/getNoticeList", params);
}
export async function getNoticeData(params) {
  return request.post("/index/basic/getNoticeData", params);
}
export async function getCertificateList(params) {
  return request.post("/index/Certificate/getCertificateList", params);
}
export async function faceDetect(params) {
  return request.post("/tool/aip/faceDetect", params);
}
export async function faceMatch(params) {
  return request.post("/tool/aip/faceMatch", params);
}
// 提交认证信息
export async function updateUserVerifyData(params) {
  return request.post("/user/user/updateUserVerifyData", params);
}
// 获取课程记录
export async function getCourseResourceData(params) {
  return request.post("/user/course/getCourseResourceData", params);
}
// 检验课程顺序
export async function checkIsOrder(params) {
  return request.post("/user/course/checkIsOrder", params);
}
// 新增课程资源评价
export async function insertCourseCommentData(params) {
  return request.post("/user/course/insertCourseCommentData", params);
}
// 新增学习时长记录
export async function insertCourseVerifyRecordData(params) {
  return request.post("/user/course/insertCourseVerifyRecordData", params);
}
// 监管人脸
export async function thirdFaceCompare(params) {
  return request.post("/regulation/Face/thirdFaceCompare", params);
}
// 更新学习进度 ,开始，结束，每分钟调用
export async function updateCourseRecordData(params) {
  return request.post("/user/course/updateCourseRecordData", params);
}
// 监管开始学习
export async function startstudy(params) {
  return request.post("/regulation/study/startstudy", params);
}
// 获取核验记录列表
export async function getCourseVerifyRecordList(params) {
  return request.post("/user/course/getCourseVerifyRecordList", params);
}
// 获取核验记录列表
export async function insertCourseStudyRecordData(params) {
  return request.post("/user/course/insertCourseStudyRecordData", params);
}
// 获取核验记录列表
export async function submitRecordVideoUrl(params) {
  return request.post("/regulation/video/submitRecordVideoUrl", params);
}
//
export async function studyHour(params) {
  return request.post("/regulation/study/studyHour", params);
}
//监管-结束学习提交
export async function endStudy(params) {
  return request.post("/regulation/study/endStudy", params);
}
// 未读消息
export async function getUserMsgIsRead(params) {
  return request.post("/regulation/push/getUserMsgIsRead", params);
}
export async function getUserMsgInfo(params) {
  return request.post("/regulation/push/getUserMsgInfo", params);
}
export async function getUserMsgList(params) {
  return request.post("/regulation/push/getUserMsgList", params);
}
/*
 * resource_id\student_id\video_study_time
 */
export async function checkFacePhoto(params) {
  return request.post("/user/course/checkFacePhoto", params);
}
/*
 * resource_id\student_id\video_study_time\
 * 返回：class_video_value
 */
export async function checkFaceVideo(params) {
  return request.post("/user/course/checkFaceVideo", params);
}
export async function AliossPolicy(params) {
  return request.post('/tool/aliyunoss/AliossPolicy', params);
}