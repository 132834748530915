import router from "@/router";
// import store from "@/store";
import ls from "@/utils/local-storage";
import store from "@/store";
// import { GENERATE_ROUTES, GET_INFO } from "@/store";
// import CameraTool from "@/components/index.js";
import Confirm from "@/components/library/confirm.js";
// 确认框
import { Modal } from "ant-design-vue";

const allowList = ["Home", "About", "Login"];
const homePath = "/login";

router.beforeEach(async (to) => {
  if (window.screen.availWidth < 900) {
    console.log("to.fullPath", to.fullPath);
    if (to.fullPath == "/intercept") {
      return true;
    }
    if (to.fullPath != "/intercept") {
      return {
        path: "/intercept",
        replace: true,
      };
    }
    return to;
  }

  const userToken = ls.get("yxzxUserId"); // token check
  if (!userToken) {
    // 白名单路由列表检查
    if (allowList.includes(to.name)) {
      return true;
    }
    if (to.fullPath !== homePath) {
      return {
        path: homePath,
        replace: true,
      };
    }

    return to;
  }

  if (
    store.getters["allowRouters"] &&
    store.getters["allowRouters"].length > 0
  ) {
    let userData = store.getters["userData"];
    if (userData?.user_status != 0 && userData?.user_status != 1) {
      userData = await store.dispatch("GET_INFO");
    }
    const registPath = [
      "/mine",
      "/course",
      "/course/course",
      "/course/resource",
      "/exam/index",
      "/exam/result",
      "/exam/exam",
      "/exam/confirm",
      "/question/index",
      "/question/question",
    ];
    if (userData?.user_id && (!userData?.user_face || !userData?.user_idcard)) {
      Modal.confirm({
        title: "认证提醒",
        content: "认证后将获取看课权限",
        cancelText: "暂不认证",
        okText: "立即认证",
        centered: true,
        onOk() {
          Confirm({ visible: true })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {},
      });
    }
    if (
      registPath.includes(to.path) &&
      (!userData?.user_face || !userData?.user_idcard)
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    const info = await store.dispatch("GET_INFO");
    if (info) {
      const allowRouters = await store.dispatch(`GET_ROUTERS`, info);
      if (allowRouters) {
        return { ...to, replace: true };
      }
    } else {
      return {
        path: homePath,
        replace: true,
      };
    }

    return false;
  }
});
