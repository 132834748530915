<template>
  <Modal
    title="人脸认证"
    v-model:visible="cameraVisible"
    :maskClosable="false"
    :width="600"
    :footer="false"
    :closable="closeable"
    :centered="true"
    :bodyStyle="{ position: 'relative', overflow: 'hidden' }"
    @cancel="closeModal"
  >
    <Spin :spinning="loading">
      <div class="step-box">
        <Steps :current="stepNum" size="small">
          <Step title="用户信息"></Step>
          <Step title="人脸认证"></Step>
        </Steps>
      </div>
      <div v-if="stepNum == 0" class="info-box">
        <a-form>
          <a-form-item label="姓名" required>
            <a-input v-model:value="form.user_name"></a-input>
          </a-form-item>
          <a-form-item label="身份证号" required>
            <a-input v-model:value="form.user_idcard"></a-input>
          </a-form-item>
        </a-form>
      </div>
      <a-alert
        v-if="messageData.message"
        :message="messageData.message"
        :type="messageData.type"
        show-icon
        style="width: 80%; margin: 0 auto"
      ></a-alert>
      <div v-if="stepNum == 1" class="video-box">
        <div class="video-mask">
          <video-mask></video-mask>
        </div>
        <video id="video" autoplay="autoplay"></video>
      </div>
      <div class="button-box">
        <a-space>
          <a-button
            v-if="stepNum == 0"
            type="primary"
            size="large"
            @click="goNext()"
            >下一步</a-button
          >
          <a-button
            v-if="stepNum == 1"
            type="primary"
            size="large"
            :disabled="submitDisableBtn"
            @click="submitForm()"
            >提交</a-button
          >
          <a-button
            v-if="closeable"
            type="primary"
            size="large"
            @click="closeModal()"
            >关闭窗口</a-button
          >
        </a-space>
      </div>
      <canvas id="canvas" width="500" height="500"></canvas>
    </Spin>
  </Modal>
</template>

<script>
// 注意：当前组件不是在 #app 下进行渲染，无法使用 #app 下的环境（全局组件，全局指令，原型属性函数）
import { reactive, ref, computed, nextTick, watch } from "vue";
import { onClickOutside, toRefs } from "@vueuse/core";
import { faceDetect, faceMatch, updateUserVerifyData } from "@/api/main.js";
import VideoMask from "./videoMask.vue";
import { uploadFile } from "@/utils/ali-oss/upload.js";
import {
  Button,
  Modal,
  Spin,
  Steps,
  Form,
  Input,
  Alert,
  Space,
  message,
  notification,
} from "ant-design-vue";
import { getUserData, getStudentData } from "@/utils/storeData.js";
import store from "@/store";

export default {
  name: "XtxConfirm",
  components: {
    Modal,
    Spin,
    Steps,
    Step: Steps.Step,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AAlert: Alert,
    ASpace: Space,
    AButton: Button,
    VideoMask,
  },
  props: {
    title: {
      type: String,
      default: "温馨提示",
    },
    text: {
      type: String,
      default: "",
    },
    // 确认按钮
    confirmCallback: {
      type: Function,
      default: () => {},
    },
    // 取消按钮
    cancelCallback: {
      type: Function,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    replaceUrl: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    // 点击 target 目标元素外部相当于点击了取消
    const target = ref(null);
    const pageData = reactive({
      loading: false,
      mediaStreamTrack: null,
      userData: computed(() => getUserData()).value,
      closeable: false,
      errorMessage: null,
      studentList: null,
      cameraVisible: false,
      form: {
        user_name: "",
        user_idcard: "",
        user_id: "",
        user_face: "",
        user_face_token: "",
      },
      stepNum: 0,
      messageData: { message: null, type: "error" },
      student_photo: null,
      videoCount: 0,
      submitDisableBtn: true,
    });
    onClickOutside(target, () => {
      props.cancelCallback();
    });

    const closeModal = () => {
      closeVideo();
      pageData.messageData.message = null;
      pageData.messageData.type = "success";
      props.confirmCallback();
      notification.destroy();
    };

    const trim = (str) => {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    };
    //18位生日校验
    const isValidityBrithBy18IdCard = (idCard18) => {
      var year = idCard18.substring(6, 10);
      var month = idCard18.substring(10, 12);
      var day = idCard18.substring(12, 14);
      var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
      // 这里用getFullYear()获取年份，避免千年虫问题
      if (
        temp_date.getFullYear() != parseFloat(year) ||
        temp_date.getMonth() != parseFloat(month) - 1 ||
        temp_date.getDate() != parseFloat(day)
      ) {
        return false;
      } else {
        return true;
      }
    };
    //18位最后一位校验
    const isTrueValidateCodeBy18IdCard = (a_idCard) => {
      var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子
      var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 身份证验证位值.10代表X
      var sum = 0; // 声明加权求和变量
      if (a_idCard[17].toLowerCase() == "x") {
        a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
      }
      for (var i = 0; i < 17; i++) {
        sum += Wi[i] * a_idCard[i]; // 加权求和
      }
      var valCodePosition = sum % 11; // 得到验证码所位置
      if (a_idCard[17] == ValideCode[valCodePosition]) {
        return true;
      } else {
        return false;
      }
    };
    const isValidityBrithBy15IdCard = (idCard15) => {
      const year = idCard15.substring(6, 8);
      const month = idCard15.substring(8, 10);
      const day = idCard15.substring(10, 12);
      const tempDate = new Date(year, parseFloat(month) - 1, parseFloat(day));
      if (
        tempDate.getYear() !== parseFloat(year) ||
        tempDate.getMonth() !== parseFloat(month) - 1 ||
        tempDate.getDate() !== parseFloat(day)
      ) {
        return false;
      } else {
        return true;
      }
    };

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          pageData.cameraVisible = true;
          let user = pageData.userData;
          pageData.form.user_name = user?.user_name;
          pageData.form.user_idcard = user?.user_idcard;
          pageData.form.user_id = user?.user_id;
          if (user.user_id && (!user.user_face || !user.user_idcard)) {
            getStudentData()
              .then((res) => {
                pageData.student_photo = res[res.length - 1].student_photo;
              })
              .catch((res) => {
                console.log(res);
              });
          }
        }
      },
      { immediate: true }
    );

    // 提示
    const noticeFun = (type, message) => {
      pageData.messageData.message = message;
      pageData.messageData.type = type;
      notification[type]({
        message: message,
        duration: 2.5,
      });
    };
    // base64转为file
    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };
    // 上传图片
    const uploadFileFun = (_img, _name) => {
      return new Promise((resolve, reject) => {
        uploadFile(
          _img,
          "index/renzheng/",
          "image",
          _name,
          function (res) {
            resolve(res);
          },
          function (res) {
            reject(res);
          }
        );
      });
    };
    // 人脸对比
    const faceMatchFun = (_token) => {
      return new Promise((resolve, reject) => {
        faceMatch({
          image_1: pageData.student_photo,
          image_type_1: "URL",
          image_2: _token,
          image_type_2: "FACE_TOKEN",
        })
          .then((res) => {
            if (res.code == 200) {
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch((res) => {
            console.log(res);
            reject(res);
          });
      });
    };
    // 人脸检测
    const faceDetectFun = (_img) => {
      return new Promise((resolve, reject) => {
        faceDetect({ image: _img, imageType: "BASE64" })
          .then((res) => {
            if (res.code == 200) {
              if (res.data.face_token && !pageData.userData.user_face_token) {
                resolve(res.data.face_token);
              }
            } else {
              reject(res);
            }
          })
          .catch((res) => {
            console.log(res);
            reject(res);
          });
      });
    };
    // 拍摄照片
    const takePhoto = () => {
      //获得Canvas对象
      let video = document.getElementById("video");
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, 500, 500);
      let url = canvas.toDataURL("image/jpg");
      if (url) {
        let img = url.replace("data:image/png;base64,", "");
        faceDetectFun(img)
          .then((res) => {
            pageData.form.user_face_token = res;
            return faceMatchFun(res);
          })
          .then(() => {
            let _imgFileName =
              new Date().getTime() + Math.floor(Math.random() * 150) + ".jpg";
            let _imgFile = dataURLtoFile(url, _imgFileName);
            return uploadFileFun(_imgFile, _imgFileName);
          })
          .then((res) => {
            pageData.form.user_face = res;
            noticeFun("success", "人脸识别成功");
            pageData.submitDisableBtn = false;
          })
          .catch((res) => {
            console.log(res);
            noticeFun("error", res?.msg || "发生错误，请稍后再试");
            pageData.videoCount++;
            url = null;
            // 核验人脸10次显示关闭弹窗
            if (pageData.videoCount > 9) {
              noticeFun("error", "认证次数过多，请稍后再试");
              closeVideo();
              pageData.closeable = true;
              return false;
            } else {
              setTimeout(() => {
                pageData.messageData.message = "正在拍摄，请勿眨眼";
                pageData.messageData.type = "warning";
                notification["warning"]({
                  message: "正在拍摄，请勿眨眼",
                  duration: 2.5,
                  onClose() {
                    takePhoto();
                  },
                });
              }, 3000);
            }
          });
      }
    };
    const getStream = (stream) => {
      var video = document.getElementById("video");
      pageData.mediaStreamTrack = stream.getTracks()[0];
      video.srcObject = stream;
      video.onloadedmetadata = function () {
        video.play();
        pageData.loading = false;
        noticeFun("info", "把脸移入框内，请直视摄像头");
        setTimeout(() => {
          pageData.messageData.message = "正在拍摄，请勿眨眼";
          pageData.messageData.type = "warning";
          notification["warning"]({
            message: "正在拍摄，请勿眨眼",
            duration: 2.5,
            onClose() {
              takePhoto();
            },
          });
        }, 3000);
      };
    };
    const getStreamError = (err) => {
      console.log(err.name + ": " + err.message);
      if (err.name == "NotAllowedError" && err.message == "Permission denied") {
        noticeFun("error", "请打开摄像头权限");
        // message.error("请授权使用摄像头");
      } else {
        noticeFun("error", "摄像头调取失败:" + err?.name + ":" + err?.message);
      }
      pageData.loading = false;
      pageData.closeable = true;
    };

    // 获取摄像头
    const getMedia = () => {
      // // 得到摄像头api
      if (!navigator.mediaDevices && !navigator.mediaDevices?.getUserMedia) {
        navigator.userMedia =
          navigator.getUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia;
        if (!navigator.userMedia) {
          // alert("浏览器不支持拍照，请更换浏览器！");
          Modal.error({
            title: "浏览器不支持拍照，请更换浏览器！",
            onOk() {
              window.location.reload();
            },
          });
          return false;
        }
        navigator.userMedia(
          {
            video: { width: 500, height: 500 },
            //关闭video话筒false，开启true
            audio: false,
          },
          (stream) => getStream(stream),
          (err) => getStreamError(err)
        );
        return false;
      }

      if (navigator.mediaDevices && navigator.mediaDevices?.getUserMedia) {
        // 如果我们想要立即显示拍照，请设置 { video: true }
        navigator.mediaDevices
          .getUserMedia({
            video: { width: 500, height: 500 },
            //关闭video话筒false，开启true
            audio: false,
          })
          .then(function (stream) {
            getStream(stream);
          })
          .catch(function (err) {
            getStreamError(err);
          });
      }
    };

    const closeVideo = () => {
      pageData.mediaStreamTrack?.stop();
    };

    const _goNext = () => {
      pageData.loading = true;
      pageData.stepNum = 1;
      nextTick(() => {
        getMedia();
      });
    };

    const goNext = () => {
      if (!pageData.form.user_name) {
        message.warning("请填写姓名");
        return false;
      }
      const idCards = trim(pageData.form.user_idcard.replace(/ /g, ""));
      if (idCards.length === 15) {
        // console.log('15位')
        if (isValidityBrithBy15IdCard(idCards)) {
          _goNext();
        } else {
          message.warning("身份证号码填写有误");
        }
      } else if (idCards.length === 18) {
        // console.log('18位')
        const idCardS = idCards.split(""); // 得到身份证数组
        if (
          isValidityBrithBy18IdCard(idCards) &&
          isTrueValidateCodeBy18IdCard(idCardS)
        ) {
          _goNext();
        } else {
          message.warning("身份证号码填写有误");
        }
      } else {
        message.warning("身份证号码填写有误");
      }
    };

    const submitForm = () => {
      console.log("form", pageData.form);
      let data = {
        user_id: null,
        user_name: null,
        user_idcard: null,
        user_face: null,
        user_face_token: null,
      };
      Object.assign(data, pageData.form);
      updateUserVerifyData(data)
        .then(async (res) => {
          if (res.code == 200) {
            message.success("操作成功");
            await store.dispatch("GET_INFO");
            closeModal();
            window.location.reload();
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };

    return { target, ...toRefs(pageData), closeModal, goNext, submitForm };
  },
};
</script>

<style scoped lang="less">
.xtx-confirm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba(0, 0, 0, 0.5);
  .wrapper {
    width: 400px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .header,
    .footer {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: #1890f1;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: right;
      .xtx-button {
        margin-left: 20px;
      }
    }
    .header {
      position: relative;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>

<style lang="less">
.step-box {
  width: 80%;
  margin: 0 auto 40px;
}
.info-box {
  margin-bottom: 40px;
}
.video-box {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  #video {
    width: 100%;
    height: 100%;
    // object-fit: fill;
  }
}
.button-box {
  text-align: center;
}
#canvas {
  position: absolute;
  top: 9999px;
}
</style>
