import store from "../store";

export const getUserId = () => {
  return store.getters["userId"];
};
export const getUserData = () => {
  return store.getters["userData"];
};
export const getUserVip = () => {
  return store.getters["userData"].user_isvip;
};
export const getStudentData = async () => {
  let student = [];
  // let student = (await store.get) / ters["studentData"];
  // if (!student.length > 0) {
  let _student = await store.dispatch("GET_STUDENT");
  student = _student.student;
  // }
  return student;
  // return new Promise((resolve, reject) => {});
};
