import { createRouter, createWebHashHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Layouts from "@/components/layouts/index.vue";

export const staticRoutes = [
  {
    path: "/",
    name: "Home",
    component: Layouts,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "Home",
        component: () => import("../views/home/Home.vue"),
      },
      {
        path: "/course",
        name: "Course",
        component: () => import("../views/course/index.vue"),
      },
      {
        path: "/course/course",
        name: "CoursePage",
        component: () => import("../views/course/course.vue"),
      },
      {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue"),
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/login.vue"),
      },
      {
        path: "/course/resource",
        name: "ResourcePage",
        component: () => import("../views/course/resource.vue"),
      },
      {
        path: "/question/index",
        name: "QuestionIndex",
        component: () => import("../views/question/index.vue"),
      },
      {
        path: "/question/question",
        name: "QuestionQuestion",
        component: () => import("../views/question/question.vue"),
      },
      {
        path: "/exam/index",
        name: "ExamIndex",
        component: () => import("../views/exam/index.vue"),
      },
      {
        path: "/exam/confirm",
        name: "ExamConfirm",
        component: () => import("../views/exam/confirm.vue"),
      },
      {
        path: "/exam/exam",
        name: "ExamExam",
        component: () => import("../views/exam/exam.vue"),
      },
      {
        path: "/exam/result",
        name: "ExamResult",
        component: () => import("../views/exam/result.vue"),
      },
      {
        path: "/mine",
        name: "Mine",
        component: () => import("../views/mine/index.vue"),
      },
      {
        path: "/intercept",
        name: "Intercept",
        component: () => import("../views/Intercept.vue"),
      },
    ],
  },
];
export const routes = [
  {
    path: "/index",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/course",
    name: "Course",
    component: () => import("../views/course/index.vue"),
  },
  {
    path: "/course/course",
    name: "CoursePage",
    component: () => import("../views/course/course.vue"),
  },
  {
    path: "/course/resource",
    name: "ResourcePage",
    component: () => import("../views/course/resource.vue"),
  },
  {
    path: "/question/index",
    name: "QuestionIndex",
    component: () => import("../views/question/index.vue"),
  },
  {
    path: "/question/question",
    name: "QuestionQuestion",
    component: () => import("../views/question/question.vue"),
  },
  {
    path: "/exam/index",
    name: "ExamIndex",
    component: () => import("../views/exam/index.vue"),
  },
  {
    path: "/exam/confirm",
    name: "ExamConfirm",
    component: () => import("../views/exam/confirm.vue"),
  },
  {
    path: "/exam/exam",
    name: "ExamExam",
    component: () => import("../views/exam/exam.vue"),
  },
  {
    path: "/exam/result",
    name: "ExamResult",
    component: () => import("../views/exam/result.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("../views/mine/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFund",
    component: () => import("@/views/error/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: staticRoutes,
  scrollBehavior: () => {
    return {
      el: ".ant-layout-content",
      top: 64,
    };
  },
});

export default router;
