import { createStore } from "vuex";
import ls from "@/utils/local-storage";
import { default as router, staticRoutes, routes } from "@/router";
import { onLogin, getUserData, getStudentList } from "@/api/main.js";
import { message } from "ant-design-vue";

export default createStore({
  state: {
    user: {
      access_token: null,
      user_account: null,
      user_avatar: null,
      user_face: null,
      user_face_token: null,
      user_id: null,
      user_idcard: null,
      user_isvip: null,
      user_name: null,
      user_nickname: null,
      user_openid: null,
      user_phone: null,
      user_photo: null,
      user_status: null,
      user_time: null,
    },
    student: {},
    access_token: null,
    allowRouters: null,
  },
  getters: {
    userId: (state) => state.user.user_id,
    userData: (state) => state.user,
    allowRouters: (state) => state.allowRouters,
    studentData: (state) => state.student,
  },
  mutations: {
    ["SET_USERINFO"]: (state, info) => {
      state.user = info;
      // state.access_token = info.access_token;
      ls.set("yxzxUserId", info.user_id);
    },
    ["SET_ROUTERS"]: (state, info) => {
      state.allowRouters = info;
    },
    ["SET_TOKEN"]: (state, info) => {
      state.access_token = info;
    },
    ["SET_STUDENT"]: (state, info) => {
      state.student = info;
    },
    ["RESET_USERINFO"]: (state) => {
      let _userData = {
        access_token: null,
        user_account: null,
        user_avatar: null,
        user_face: null,
        user_face_token: null,
        user_id: null,
        user_idcard: null,
        user_isvip: null,
        user_name: null,
        user_nickname: null,
        user_openid: null,
        user_phone: null,
        user_photo: null,
        user_status: null,
        user_time: null,
      };
      state.user = _userData;
      state.access_token = null;
      ls.set("yxzxUserId", null);
      ls.set("yxzxAccessToken", null);
    },
  },
  actions: {
    // 存入登录localStorage 和 userinfo
    ["LOGIN"]({ commit }, info) {
      return new Promise((resolve, reject) => {
        onLogin(info)
          .then((res) => {
            if (res.code == 200) {
              resolve(res);
              ls.set("yxzxAccessToken", res.data.access_token);
              commit("SET_USERINFO", res.data);
            } else {
              message.error(res.msg);
              reject(res);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    ["GET_ROUTERS"]({ commit }, info) {
      return new Promise((resolve) => {
        // console.log("GET_ROUTERS", info);
        const { children: _, ...mainRoutes } = staticRoutes[0];
        const route = { ...mainRoutes, children: routes };
        router.addRoute(route);
        commit("SET_ROUTERS", routes);
        resolve(routes);
      });
    },
    ["LOGOUT"]({ commit }) {
      return new Promise((resolve) => {
        // onLogout()
        //   .then((res) => {
        //     if (res.code == 200) {
        // resolve(res);
        ls.set("yxzxUserId", null);
        commit("RESET_USERINFO");
        resolve(true);
        //     } else {
        //       reject(res);
        //     }
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
      });
    },
    ["GET_INFO"]({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        let token = ls.get("yxzxAccessToken");
        let userId = ls.get("yxzxUserId");
        if (token && userId) {
          commit("SET_TOKEN", token);
          getUserData({ user_id: userId })
            .then((res) => {
              if (res.code == 200 && res.data.user) {
                commit("SET_USERINFO", res.data.user);
                if (!state.student?.student_id) {
                  dispatch("GET_STUDENT").finally(() => {
                    resolve(res.data.user);
                  });
                } else {
                  resolve(res.data.user);
                }
              } else {
                commit("RESET_USERINFO");
                resolve(false);
              }
            })
            .catch((res) => {
              console.log(res);
              reject(false);
            });
        } else {
          commit("RESET_USERINFO");
          reject(false);
        }
      });
    },
    ["GET_STUDENT"]({ commit }) {
      return new Promise((reslove, reject) => {
        let userId = ls.get("yxzxUserId");
        getStudentList({ user_id: userId })
          .then((res) => {
            if (res.code == 200) {
              let student = res.data.student;
              commit("SET_STUDENT", student);
              reslove({
                code: 200,
                student,
              });
            } else {
              console.log("res", res);
              reject(false);
            }
          })
          .catch((res) => {
            console.log(res);
            reject(false);
          });
      });
    },
  },
  modules: {},
});
