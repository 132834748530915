<template>
  <div class="notice-icon-page">
    <a-badge :count="noReadCount">
      <div class="icon">
        <bell-outlined @click="openNoticeModal" />
      </div>
    </a-badge>
    <a-modal
      v-model:visible="noticeModalVisible"
      :closable="true"
      :maskClosable="false"
      :footer="false"
      :centered="true"
      title="消息列表"
      width="700px"
      @cancel="closeModal"
    >
      <a-spin :spinning="loading">
        <div v-if="!loading && noticeList.length != 0" class="notice-list">
          <a-list
            size="small"
            :pagination="pagination"
            :data-source="noticeList"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                <template #actions>
                  <a-button size="small" type="link" @click="hasRead(item)">
                    查看</a-button
                  >
                </template>
                <a-list-item-meta description="">
                  <template #title>
                    <div class="flex">
                      <div
                        v-if="item.is_read == 0"
                        class="read-icon no-read"
                      ></div>
                      <div
                        v-if="item.is_read == 1"
                        class="read-icon is-read"
                      ></div>
                      <!-- style="width: 8px; height: 8px; border-radius: 50%" -->
                      <!-- background: #ff4d4f; -->
                      <div class="msg-content">{{ item.msg_content }}</div>
                    </div>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </div>
        <a-empty
          v-if="!loading && noticeList.length == 0"
          description="暂无消息"
        ></a-empty>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import {
  toRefs,
  defineComponent,
  reactive,
  computed,
  onMounted,
  h,
  watch,
} from "vue";
import { BellOutlined } from "@ant-design/icons-vue";
import {
  getUserMsgIsRead,
  getUserMsgInfo,
  getUserMsgList,
} from "@/api/main.js";
import { getUserId } from "../../utils/storeData";
import { message, Modal } from "ant-design-vue";

export default defineComponent({
  name: "NoticeIcon",
  components: {
    BellOutlined,
  },
  setup() {
    const pageData = reactive({
      noReadCount: 0,
      user_id: computed(() => getUserId()).value,
      noticeModalVisible: false,
      noticeList: [],
      loading: false,
      pagination: {
        onChange: (page) => {
          pageData.pagination.current = page;
        },
        pageSize: 10,
        limit: 1,
        current: 1,
        total: 0,
      },
      notice: {},
    });
    const getTips = () => {
      Modal.confirm({
        title: "温馨提醒",
        content: "您有未读消息，请及时查阅",
        onOk() {
          pageData.noticeModalVisible = true;
        },
      });
    };
    const getUserMsgIsReadFun = () => {
      getUserMsgIsRead({
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.noReadCount = res.data?.count;
            if (res.data?.count > 0) {
              getTips();
            }
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error(res?.msg || "网络有误，请稍后再试");
        });
    };
    const getUserMsgListFun = () => {
      getUserMsgList({
        user_id: pageData.user_id,
        page: pageData.pagination.current,
        limit: pageData.pagination.pageSize,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.noticeList = res.data?.usermsg;
            pageData.pagination.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error(res?.msg || "网络有误，请稍后再试");
        });
    };
    onMounted(() => {
      getUserMsgListFun();
      getUserMsgIsReadFun();
    });

    watch(
      () => pageData.pagination.current,
      (val) => {
        console.log("val", val);
        getUserMsgListFun();
      }
    );

    const openNoticeModal = () => {
      pageData.noticeModalVisible = true;
    };
    const closeModal = () => {
      pageData.noticeModalVisible = false;
    };
    const hasRead = (item) => {
      getUserMsgInfo({ msg_id: item.msg_id })
        .then((res) => {
          if (res.code == 200) {
            pageData.notice = res.data.info;
            Modal.info({
              content: h("div", {}, [
                h("p", { style: "font-size:16px" }, res.data.info?.msg_content),
                h(
                  "p",
                  { style: "text-align:right;color:#8d8d8d" },
                  res.data.info?.record_time
                ),
              ]),
              onOk() {
                getUserMsgListFun();
                getUserMsgIsReadFun();
              },
            });
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    const getContent = (content) => {
      console.log("content.length", content.length);
      if (content.length > 40) {
        return content.slice(0, 40) + "...";
      } else {
        return content;
      }
    };

    return {
      ...toRefs(pageData),
      openNoticeModal,
      closeModal,
      hasRead,
      getContent,
    };
  },
});
</script>

<style lang="less">
.notice-icon-page {
  margin-right: 28px;
  cursor: pointer;
  .icon {
    padding: 4px;
    vertical-align: middle;
    font-size: 18px;
  }
}
.read-icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}
.no-read {
  background-color: #ff4d4f;
}
.is-read {
  background-color: #bcbcbc;
}
.msg-content {
  width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
